import { zipObject } from "@gromia/utils/fp";
import queryString from "query-string";
import Cookie from "universal-cookie";

type TrackingData = {
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_content?: string;
  utm_ad?: string;
  utm_adset?: string;
};

export const keys: Array<keyof TrackingData> = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_ad",
  "utm_adset",
];

export function getTrackingData(): TrackingData {
  const params = queryString.parse(window.location.search);
  const cookies = new Cookie(keys);

  return zipObject(
    keys,
    keys.map(n => params[n] || cookies.get(n) || undefined),
  );
}

export function saveTrackingData(d: TrackingData): void {
  const cookies = new Cookie(keys);
  keys.forEach(k => {
    const v = d[k];
    if (v !== undefined && v !== null) {
      cookies.set(k, v);
    }
  });
}
